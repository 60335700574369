import { gql } from "@apollo/client";

export const ADD_FILE = gql`
   mutation singleFileUpload($ETag:String, $Location:String, $key:String, $Key:String, $Bucket:String,$label:String) {
  singleFileUpload(ETag:$ETag, Location:$Location, key:$key, Key:$Key, Bucket:$Bucket,label:$label) {
files {
   _id
   ETag
   Location
   key
   Key
   Bucket
    }
  }
}

`;

export const ADD_CONTACT = gql`
mutation addContact(
 $firstName: String!,
 $lastName: String!,
 $email: String!,
 $password: String!,
 $descriptionText: String,
 $phone: String
 $pricePack: String
 ) {
  addContact(
   firstName: $firstName,
   lastName: $lastName,
   email: $email,
   password: $password,
   descriptionText: $descriptionText,
   phone: $phone,
   pricePack: $pricePack
    ) {
    token
    user {
     _id
    }   
  }
}
`;

export const LOGIN = gql`
mutation loginContact($email: String!, $password: String!) {
  loginContact(email: $email, password: $password) {
    token
    user {
      _id
    }
  }
}
`;

export const LEAVE_REVIEW = gql`
mutation addReview($reviewText: String!, $firstName: String!, $lastName: String!,$rating: Int) {
  addReview(reviewText: $reviewText, firstName: $firstName, lastName: $lastName, rating: $rating) {
    _id
  }
}
`;

export const EDIT_USER = gql`
mutation updateContact($contactId: ID!, $firstName: String, $lastName: String, $email: String, $descriptionText: String, $phone: String) {
  updateContact(contactId: $contactId, firstName: $firstName, lastName: $lastName, email: $email, descriptionText: $descriptionText, phone: $phone) {
    _id
  }
}
`;

export const ADD_PROFILE_PIC = gql`
mutation profilePictureUpload($ETag: String! $Location:String! $key:String $Key:String! $Bucket:String!,$usersId:ID) {
  profilePictureUpload(ETag:$ETag Location:$Location key:$key Key:$Key Bucket:$Bucket,usersId:$usersId) {
 profilePic{
  Bucket
  Key
  Location
 }
  }
}
`;

export const DELETE_PROJECT_IMG = gql`
mutation removeFileById($fileId: ID!) {
  removeFileById(fileId: $fileId) {
    _id
  }
}
`;

export const DELETE_ACCOUNT = gql`
mutation deleteContact($contactId: ID!) {
  deleteContact(contactId: $contactId) {
    _id
  }
}
`;

export const UPDATE_REVIEW = gql`
mutation updateReview($reviewId: ID!, $reviewText: String! $rating: Int!) {
  updateReview(reviewId: $reviewId reviewText: $reviewText rating: $rating) {
    _id
  }
}
`;

export const DELETE_REVIEW = gql`
mutation deleteReview($reviewId: ID!) {
  deleteReview(reviewId: $reviewId)
}
`;

// this is for adding abouts on the admin side of the app mission statement ETC.
export const ADD_ABOUT = gql`
mutation addAbout($missionStatement: String!, $aboutTopDev: String!, $aboutFounder: String! ) {
  addAbout(missionStatement: $missionStatement, aboutTopDev: $aboutTopDev, aboutFounder: $aboutFounder) {
_id
}
}
`;
// this mutation adds the about pictures
export const ADD_ABOUT_PIC = gql`
mutation addAboutPic($ETag: String, $Location: String, $key: String, $Key:String, $Bucket: String, $_id: ID!) {
  addAboutPic(ETag: $ETag, Location: $Location, key: $key, Key: $Key, Bucket: $Bucket, _id: $_id) {
   _id
  }
}
`;

// this mutation adds the about videos
export const ADD_ABOUT_VID = gql`
mutation addAboutVid($ETag: String, $Location: String, $key: String, $Key:String, $Bucket: String, $_id: ID!) {
  addAboutVid(ETag: $ETag, Location: $Location, key: $key, Key: $Key, Bucket: $Bucket, _id: $_id) {
   _id
  }
}
`;

// mutation to delete an about
export const DELETE_ABOUT = gql`
 mutation deleteAbout($aboutId: ID!) {
  deleteAbout(aboutId: $aboutId) 
}
`;

// this will update the about text
export const UPDATE_ABOUT = gql`
mutation updateAbout($aboutId: ID!, $missionStatement: String, $aboutTopDev: String, $aboutFounder: String) {
  updateAbout(aboutId: $aboutId, missionStatement: $missionStatement, aboutTopDev: $aboutTopDev, aboutFounder: $aboutFounder) {
    _id
  }
}
`;

// this deletes a about picture
export const DELETE_ABOUT_PIC = gql`
mutation removeAboutPicById($aboutId: ID!, $picId: ID!) {
  removeAboutPicById(aboutId: $aboutId, picId: $picId) {
    aboutPics {
     _id 
    }
  }
}
`;

// this deletes a about video
export const DELETE_ABOUT_VID = gql`
mutation removeAboutVidById($aboutId: ID!, $vidId: ID!) {
  removeAboutVidById(aboutId: $aboutId, vidId: $vidId) {
    aboutVids {
     _id 
    }
  }
}
`;
/**add app questions mutation*/
export const ADD_USER_QUESTIONS = gql`
mutation addAppQuestions($quesOne:String!,$quesTwo:String!,$quesThree:String!,$quesFour:String!,$quesFive:String!,$quesSix:String!,$quesSeven:String!,$quesEight:String!,$quesNine:String!,$quesTen:String!,$quesEleven:String!,$quesTwelve:String!){
  addAppQuestions(quesOne:$quesOne,quesTwo:$quesTwo,quesThree:$quesThree,quesFour:$quesFour,quesFive:$quesFive,quesSix:$quesSix,quesSeven:$quesSeven,quesEight:$quesEight,quesNine:$quesNine,quesTen:$quesTen,quesEleven:$quesEleven,quesTwelve:$quesTwelve){
    _id
    appQuestions {
      _id
      contact {
        _id
        firstName
        lastName
      }
      quesOne
      quesTwo
      quesThree
      quesFour
      quesFive
      quesSix
      quesSeven
      quesEight
      quesNine
      quesTen
      quesEleven
      quesTwelve
    }
  }
}`;

/**added 8-17-2023 category added Dec 9 2023*/

export const ADD_PRICE_PACK = gql`
mutation addPricePack($name:String!,$price:String!,$benifits:[String!],$moreInfo:String!,$color:String!,$type:String!,$category:String!) {
  addPricePack(name:$name,price:$price,benifits:$benifits,moreInfo:$moreInfo,color:$color,type:$type,category:$category) {
    _id
    name
    price
    benifits
    moreInfo
    color
  }
}
`;

export const DLT_PRICE_PACK = gql`
mutation deletePricePackage($pricePackId: ID!) {
  deletePricePackage(pricePackId: $pricePackId) {
    _id
    name
    price
    benifits
    moreInfo
  }
}
`;
/**added Aug 28 2023*/
export const ADD_REVISION = gql`
mutation addRevision($parentId:ID! $revisionText:String! $revisionType:String!) {
  addRevision(parentId:$parentId revisionText: $revisionText, revisionType:$revisionType) {
_id
revisions {
  _id
  createdAt
  revisionText
}
  }
}
`;

export const EDIT_PROJECT_DETAILS =   gql`
mutation UpdateProjectDetails(
  $projectId: ID!
  $updateData: ProjectDetailsUpdateInput!
) {
  updateProjectDetails(projectId: $projectId, updateData: $updateData) {
    createdAt
 
  }
}
`;

export const ADD_PROJECTDETAILS = gql`
mutation CreateProjectDetails(
  $parentAccnt: ID!
  $logo: ID
  $desiredDomainName: String
  $primaryColor: String!
  $secondaryColor: String!
  $smallUseColor: String!
  $industry: String!
  $facebookLink: String
  $twitterLink: String
  $instaLink: String
  $otherLink: String
  $brandStyle: String!
) {
  createProjectDetails(
    parentAccnt: $parentAccnt
    logo: $logo
    desiredDomainName: $desiredDomainName
    primaryColor: $primaryColor
    secondaryColor: $secondaryColor
    smallUseColor: $smallUseColor
    industry: $industry
    facebookLink: $facebookLink
    twitterLink: $twitterLink
    instaLink: $instaLink
    otherLink: $otherLink
    brandStyle: $brandStyle
  ) {
    _id
    createdAt
  }
}
`;
export const LOGO_UPLOAD = gql`
mutation logoUpload( 
    $ETag:String,
    $Location:String,
    $key:String,
    $Key:String,
    $Bucket:String){
     logoUpload(ETag: $ETag,Location:$Location,key:$key
     Key:$Key,Bucket:$Bucket){
_id
ETag
Location
     }
     
   }
`;

export const UPDATE_LOGO = gql`
mutation updateLogo($logoId:ID!
    $ETag:String,
    $Location:String,
    $key:String,
    $Key:String,
    $Bucket:String,
   ) {
     updateLogo(logoId:$logoId,ETag:$ETag,Location:$Location, key:$key,Key:$Key,Bucket:$Bucket)
     {
       _id
     }
   }
`;

/**added 9-5-2023*/

export const ADD_FEATURE = gql`
mutation addFeature($featureText: String!,$title: String!) {
  addFeature(featureText:$featureText,title:$title) {
    _id
   email
   features {
     _id
     title
     featureText
   }
  }
}
`;

export const UPDATE_FEATURE_ADMIN = gql`
mutation adminUpdateFeature($featureText:String,$status:String!,$featureId: ID!,$title: String) {
  adminUpdateFeature(featureText:$featureText,status:$status,featureId:$featureId,title: $title) {
    featureText
    status
    title
  }
}
`;

export const UPDATE_FEATURE_USER =  gql`
mutation userUpdateFeature($featureText:String!,$featureId: ID!) {
  userUpdateFeature(featureText:$featureText,featureId:$featureId) {
    featureText
    status
  }
}
`;

export const DELETE_FEATURE = gql`
mutation deleteFeature($featureId:ID!) {
  deleteFeature(featureId: $featureId) {
    _id
  }
}
`;